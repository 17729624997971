<template>
  <SignUpConfirmation v-if="signupComplete && !emailVerificationPending" :success-message="accountCreatedWithoutEmailVerification" />
  <SignUpVerifyEmailInformation
    v-else-if="signupComplete && emailVerificationPending"
    :is-in-modal="isInModal"
    :verify-email-text="verifyEmailText"
  />
  <div v-else>
    <div
      class="text-xsmall"
      :class="{
        'px-24 sm:px-0': isInModal,
      }"
    >
      <form @submit.prevent="signup">
        <div class="text-xsmall-medium sm:text-small-medium mb-12">
          {{ translation.signupTitle }}
        </div>
        <div class="mb-12">
          <div class="h-40 sm:h-48">
            <input
              v-model="firstName"
              type="text"
              autocomplete="given-name"
              class="form-input"
              :class="{
                'has-error form-input--error': backendValidation.FirstName,
              }"
              :placeholder="translation.signupFirstName"
              @keydown="checkLetterOnly"
              @input="backendValidation.FirstName = ''"
            >
          </div>
          <div v-if="backendValidation.FirstName" class="mt-6 text-xsmall-medium text-error">
            {{ backendValidation.FirstName }}
          </div>
        </div>
        <div class="mb-12">
          <div class="h-40 sm:h-48">
            <input
              v-model="lastName"
              type="text"
              autocomplete="family-name"
              class="form-input"
              :class="{
                'has-error form-input--error': backendValidation.LastName,
              }"
              :placeholder="translation.signupLastName"
              @keydown="checkLetterOnly"
              @input="backendValidation.LastName = ''"
            >
          </div>
          <div v-if="backendValidation.LastName" class="mt-6 text-xsmall-medium text-error">
            {{ backendValidation.LastName }}
          </div>
        </div>
        <div class="mb-12">
          <div class="h-40 sm:h-48">
            <input
              v-model="email"
              type="email"
              autocomplete="email"
              novalidate
              class="form-input"
              :class="{
                'has-error form-input--error': backendValidation.Email,
              }"
              :placeholder="translation.loginEmail"
              :use-check-letter-only="true"
              @input="backendValidation.Email = ''"
              @change="checkEmail"
            >
          </div>
          <div v-if="backendValidation.Email" class="mt-6 text-xsmall-medium text-error">
            {{ backendValidation.Email }}
          </div>
          <div
            v-if="wrongEmail"
            class="mt-6 cursor-pointer text-small-regular"
            @click="fixEmail"
            v-html="wrongEmail"
          />
        </div>
        <div class="mb-8">
          <div class="relative h-40 sm:h-48">
            <input
              v-model="phoneNumber"
              type="tel"
              :placeholder="translation.signupPhone + ' (' + translation.optional + ')'"
              autocomplete="phone"
              class="form-input"
              :class="{
                'has-error form-input--error': backendValidation.PhoneNumber,
              }"
              @input="backendValidation.PhoneNumber = ''"
            >
          </div>
          <div v-if="backendValidation.PhoneNumber" class="mt-6 text-xsmall-medium text-error">
            {{ backendValidation.PhoneNumber }}
          </div>
        </div>
        <div class="mb-12">
          <div class="relative h-40 sm:h-48">
            <input
              v-model="password"
              :type="showPassword ? 'text': 'password'"
              autocomplete="new-password"
              class="form-input"
              :class="{
                'form-input--error': backendValidation.Password || passwordError,
              }"
              :placeholder="translation.signupPassword"
              @input="backendValidation.Password = '', passwordError = ''"
            >
            <div
              class="absolute top-0 right-0 h-40 w-40 flex items-center justify-center sm:w-48 sm:h-48"
              @click.stop="toggleShowPassword"
            >
              <img
                :src="showPassword ? '/heroicons/outline/eye-slash.svg' : '/heroicons/outline/eye.svg'"
                alt="Password visibility"
                class="inline-block h-20 w-20"
                height="20"
                width="20"
              >
            </div>
          </div>
          <div v-if="passwordError || backendValidation.Password" class="mt-6 text-xsmall-medium text-error">
            {{ passwordError || backendValidation.Password }}
          </div>
        </div>
        <div class="mb-16">
          <div class="relative h-40 sm:h-48">
            <input
              v-model="password2"
              :type="showPassword2 ? 'text': 'password'"
              autocomplete="new-password"
              class="form-input"
              :class="{
                'form-input--error': backendValidation.ConfirmPassword,
              }"
              :placeholder="translation.signupPasswordConfirm"
            >
            <div
              class="absolute top-0 right-0 h-40 w-40 flex items-center justify-center sm:w-48 sm:h-48"
              @click.stop="toggleShowPassword2"
            >
              <img
                :src="showPassword2 ? '/heroicons/outline/eye-slash.svg' : '/heroicons/outline/eye.svg'"
                alt="Password visibility"
                class="inline-block h-20 w-20"
                height="20"
                width="20"
              >
            </div>
          </div>
          <div v-if="backendValidation.ConfirmPassword" class="mt-6 text-xsmall-medium text-error">
            {{ backendValidation.ConfirmPassword }}
          </div>
        </div>
        <Checkbox
          v-if="!hasAlreadyAcceptedNewsletter"
          class="mb-12"
          :checked="newsletter"
          :text="translation.signupAcceptNewsletter"
          @on-check="newsletter = !newsletter"
        />
        <Checkbox
          v-if="!hasAlreadyAcceptedSms"
          class="mb-12"
          :checked="acceptSms"
          :text="translation2.acceptSms"
          @on-check="acceptSms = !acceptSms"
        />
        <div
          v-if="generalError"
          class="bg-error mb-8 rounded-md text-white p-12 flex items-center"
        >
          <ExclamationCircleIcon
            class="w-24 text-white mr-12"
          />
          {{ generalError }}
        </div>
        <GlobalsButton
          :text="translation.signupAction"
          :type="'submit'"
          :loading="loadSignup"
          :no-validate="true"
          class="mb-12 w-full"
        />
        <div class="wysiwyg wysiwyg--tiny-text underlineLinks" v-html="loginSettings.termsAndConditions" />
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import ExclamationCircleIcon from '@heroicons/vue/24/outline/esm/ExclamationCircleIcon';
import SignUpVerifyEmailInformation from '~/components/modals/SignUpVerifyEmailInformation.vue';
import SignUpConfirmation from '~/components/modals/SignUpConfirmation.vue';
import Checkbox from '~/components/form-elements/Checkbox.vue';
import emailMisspelled, { top100 } from 'email-misspelled';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { type IICommandResultBase } from '~/api-types';
import { storeToRefs } from 'pinia';

const emailChecker = emailMisspelled({ domains: top100 });
const { apiPost } = useApiFetch();

interface Props {
  isInModal?: boolean,
  verifyEmailText?: string,
  email?: string,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  newsletter?: boolean,
  acceptSms?: boolean,
  accountCreatedWithoutEmailVerification?: string,
}

const props = withDefaults(defineProps<Props>(), {
  isInModal: true,
  verifyEmailText: '',
  accountCreatedWithoutEmailVerification: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  newsletter: false,
  acceptSms: false,
});

const hasAlreadyAcceptedNewsletter = props.newsletter;
const hasAlreadyAcceptedSms = props.acceptSms;

const emit = defineEmits<{
  (event: 'signup-complete'): void
}>();

const signupComplete = ref(false);
const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.myPageResources;
const translation2 = globalContentStore.registrationResources;
const translation3 = globalContentStore.sharedResources;
const loginSettings = globalContentStore.loginSettings;
const marketSettings = globalContentStore.marketSettings;

const uiStore = useUiStore();
const { contactId } = storeToRefs(uiStore);
const emailVerificationPending = ref(true);

const showPassword = ref(false);
const showPassword2 = ref(false);
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};
const toggleShowPassword2 = () => {
  showPassword2.value = !showPassword2.value;
};
const loadSignup = ref(false);
const firstName = ref(props.firstName);
const lastName = ref(props.lastName ? props.lastName : '');
const email = ref(props.email ? props.email : '');
const phoneNumber = ref(props.phoneNumber ? props.phoneNumber : '');
const password = ref('');
const passwordError = ref('');
const password2 = ref('');
const newsletter = ref(props.newsletter);
const acceptSms = ref(props.acceptSms);
const generalError = ref('');
const fieldError = ref(false);
const backendValidation = ref({
  Email: '',
  FirstName: '',
  LastName: '',
  Password: '',
  ConfirmPassword: '',
  PhoneNumber: '',
});

const checkLetterOnly = (event: KeyboardEvent) => {
  const key = event.key;
  const letterRegex = /^[^a-zA-Z\u00C0-\u024F]$/;
  if (key && key.length === 1 && letterRegex.test(key)) {
    event.preventDefault();
  }
};

const resetErrors = () => {
  backendValidation.value =
    {
      Email: '',
      FirstName: '',
      LastName: '',
      Password: '',
      ConfirmPassword: '',
      PhoneNumber: '',
    };
  generalError.value = '';
};

const wrongEmail = ref('');
const suggestion = ref('');

const fixEmail = () => {
  email.value = suggestion.value;
  wrongEmail.value = '';
};

const checkEmail = () => {
  const test = emailChecker(email.value);
  if (test.length && test[0].corrected) {
    wrongEmail.value = `Menade du <span class="underline">${test[0].corrected}?`;
    suggestion.value = test[0].corrected;
  }
};

const signup = async() => {
  fieldError.value = false;
  resetErrors();

  if (!firstName.value) {
    backendValidation.value.FirstName = translation3.emptyFieldError;
    fieldError.value = true;
  }

  if (!lastName.value) {
    backendValidation.value.LastName = translation3.emptyFieldError;
    fieldError.value = true;
  }

  if (!email.value) {
    backendValidation.value.Email = translation3.emptyEmailError;
    fieldError.value = true;
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value.trim())) {
    backendValidation.value.Email = translation3.invalidEmail;
    fieldError.value = true;
  }

  // Check phone field
  // If valid, parsePhonenumber when saving
  if (phoneNumber.value) {
    let valid = false;
    try {
      valid = isValidPhoneNumber(phoneNumber.value, marketSettings.countryCode);
    } catch (e) {
      console.log(e);
      valid = false;
    }
    if (!valid) {
      backendValidation.value.PhoneNumber = translation3.invalidPhoneNumber;
      fieldError.value = true;
    } else if (valid) {
      phoneNumber.value = parsePhoneNumber(phoneNumber.value, marketSettings.countryCode).number;
    }
  }
  
  if (password.value !== password2.value) {
    passwordError.value = translation2.passwordsMustMatch;
    return;
  }

  if (fieldError.value) {
    return;
  }

  loadSignup.value = true;

  const payload = {
    countryCode: marketSettings.countryCode,
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    password: password.value,
    confirmPassword: password2.value,
    signUpToNewsletter: newsletter.value,
    acceptSms: acceptSms.value,
    contactId: contactId.value,
    returnUrl: "/kundservice/" // TODO - Add return url to checkout etc. here.
  };

  const result = await apiPost<IICommandResultBase>(
    'user',
    payload
  );

  if (result?.success) {
    loadSignup.value = false;
    uiStore.setLoginType('signupComplete');
    uiStore.setSignupStatus('signupComplete');
    signupComplete.value = true;

    if (result.userNotVerified === false) {
      emailVerificationPending.value = false;
      await globalContentStore.loadFromCms();
      await uiStore.checkLoggedIn();
    }

    emit('signup-complete');
  
    if (!props.isInModal) {
      window.scrollTo(0, 0);
    }

    if (newsletter.value) {
      window.dataLayer?.push({
        event: 'newsletter_signup',
      });
    }

    window.dataLayer?.push({
      event: 'Registration',
      category: 'user',
      action: 'newuser',
    });
  } else if (result) {
    loadSignup.value = false;
    if (result.errorMessage) {
      generalError.value = result.errorMessage;
    }
    if (result.validationErrors.length) {
      result.validationErrors.forEach((i) => {
        if (i.propertyName) {
          backendValidation.value[i.propertyName] = i.displayMessage;
        }
      });
    }
  } else {
    loadSignup.value = false;
    generalError.value = translation3.errorSomethingWentWrong;
  }
};

</script>
<style scoped>
.EPiServerForms input[type='text'],
.EPiServerForms input[type='email'],
.EPiServerForms input[type='number'] {
  animation: none;
  background-size: 24px 24px;
  background-position: right 5px center;
}

.EPiServerForms input[type='text'].has-error,
.EPiServerForms input[type='number'].has-error,
.EPiServerForms input[type='email'].has-error {
  @apply animate-none;
  background: url('/icons/icon-x-circle.svg') no-repeat;
  background-size: 24px 24px;
  background-position: right 5px center;
}
</style>
