<template>
  <div class="px-24 sm:px-0 text-xsmall">
    <div class="text-xsmall">
      <div
        class="min-h-208 bg-green mb-24 flex flex-col items-center justify-center"
      >
        <CheckCircle class="w-40 h-40 mb-16 text-success" />
        <div class="text-xsmall-medium">
          {{ registrationResources.yourAccountWasCreated }}
        </div>
      </div>
      <WysiwygWrapper
        :style-config="{
          'wysiwyg wysiwyg--sm mb-24': true,
        }"
        :html="successMessage ? successMessage : registrationResources.accountCreatedWithoutEmailVerification"
      />
      <GlobalsLinkHelper
        v-if="translation.setPreferences"
        :to="myAccountPage + '?page=Preferences'"
        class="btn btn--sm btn--primary w-full mb-12 sm:h-48"
      >
        {{ translation.setPreferences }}
      </GlobalsLinkHelper>
      <a href="/" class="btn btn--sm btn--secondary w-full sm:h-48">{{
        registrationResources.skipOver
      }}</a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import CheckCircle from '@heroicons/vue/24/outline/esm/CheckCircleIcon';
const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.myPageResources;
const registrationResources = globalContentStore.registrationResources;
const myAccountPage = globalContentStore.pageSettings.myAccountPage.url;
defineProps<{
  successMessage: string;
}>();
</script>
